@import "../../assets/iconfont/iconfont.css";
.border-none[data-v-581f942c]:before {
  display: none;
}
.border-none[data-v-581f942c]:after {
  display: none;
}
.flex-fix[data-v-581f942c] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-581f942c]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-581f942c] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-581f942c] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-581f942c] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-581f942c] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-581f942c] {
  *zoom: 1;
}
.clear-fix[data-v-581f942c]:before,
.clear-fix[data-v-581f942c]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.head_nav .yd-navbar[data-v-581f942c] {
  position: fixed;
  top: 0;
  z-index: 9;
  width: 100%;
}
.head_nav .yd-navbar .icon_img[data-v-581f942c] {
  width: 0.48rem;
  height: 0.48rem;
}
.head_nav .yd-navbar .grey[data-v-581f942c] {
  color: #b3b3b3;
  margin-top: 0.06rem;
}
.head_nav .blank[data-v-581f942c] {
  height: 0.88rem;
}
