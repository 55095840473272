@import "../../assets/iconfont/iconfont.css";
.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.head_nav .yd-navbar {
  position: fixed;
  top: 0;
  z-index: 9;
  width: 100%;
}
.head_nav .yd-navbar .icon_img {
  width: 0.48rem;
  height: 0.48rem;
}
.head_nav .yd-navbar .grey {
  color: #b3b3b3;
  margin-top: 0.06rem;
}
.head_nav .blank {
  height: 0.88rem;
}
