.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.help-center .help-list .item {
  height: 1rem;
  line-height: 1rem;
  background: #fff;
  font-size: 0.32rem;
  color: #333;
  padding: 0 0.24rem;
  background: white url(../../../assets/ic_arrow_right_gray@2x.png) no-repeat 97% 50%;
  background-size: 0.26rem 0.26rem;
  border-bottom: 0.01rem solid #e1e1e1;
}
.help-center .help-list .item:last-child {
  border: 0 ;
}
